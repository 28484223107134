import React, { Component } from "react";
import "./style.scss";
import Header from "../../header";
import Layout from "../../layout";
import ProjectLayout from "../../projectlayout2";
import sliderImages from "./slider.data";
import SEO from "../../seo";

const details = {
  title: "Amolik Group",
  address: "Faridabad Sector 19",
  client: "Amolik Group, Faridabad",
  area: "1,25,000 Sq. Ft.",
  project: "Mixed use commercial complex.",
  category: "Commercial | Architecture | Offices",
  status: "Under Design Development",
  backlink: "/projects/commercial/",
};

export default class Projectamolikgroup extends Component {
  render() {
    return (
      <Layout>
        <SEO
          title="A&D Studio | Signature Commercial Projects | Amolik Group"
          img="https://archdesignsstudio.com/assets/linkpreview.png"
          description="Explore details of the mixed-use commercial complex in Faridabad under design development for Amolik Group."
        />
        <Header isWhite />
        <ProjectLayout details={details} projectimages={sliderImages} />
      </Layout>
    );
  }
}
